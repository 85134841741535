import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Form } from 'semantic-ui-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
  placeholder: string;
  name: string;
  rows: number;
  label?: string;
  disabled?: boolean;
}

export default function MyTextAreaInput(props: Props) {
  const [field, meta] = useField(props.name);
  const { setFieldValue } = useFormikContext();

  return (
    <Form.Field error={meta.touched && !!meta.error}>
      {props.label && <label>{props.label}</label>}
      <ReactQuill
        value={field.value}
        onChange={(value) => setFieldValue(props.name, value)}
        placeholder={props.placeholder}
        readOnly={props.disabled}
        style={{ height: `${props.rows * 50}px` }}
      />
      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{meta.error}</div>
        </div>
      )}
    </Form.Field>
  );
}